.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  font-family: var(--poppins-font);
  width: 100%;
}
.about h2 {
  font-size: 3rem;
}
.about .sub-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: var(--pizza-red);
  font-family: var(--nothing-font);
  letter-spacing: 1px;
}

.about p {
  color: var(--light-gray);
  font-size: 1.2rem;
  line-height: 2rem;
}
.about-us .content h3,
.about-dedication .content h3,
.about-collaboration h3,
.about-customers h3,
.about-careers h3 {
  font-size: 2rem;
}

.about-us,
.about-dedication,
.about-customers {
  display: flex;
  flex-direction: column;
}
.about-careers,
.about-collaboration {
  display: grid;
  grid-template-columns: 1fr;
}

.about-careers img,
.about-collaboration img {
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  opacity: 0.8;
  object-fit: cover;
}
.about-careers .content,
.about-collaboration .content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.about-dedication .content,
.about-customers .content,
.about-customer .content,
.about-careers .content,
.about-collaboration .content {
  padding: 1rem;
}



.about-us,
.about-dedication {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.about-us .content {
  position: relative;
  padding: 4rem;
  z-index: 98;
  background-image: url("../../assets/images/about-us/section-one.jpg");
}



.about-us .content h2,
.about-us .content h3,
.about-us .content p {
  position: relative;
  z-index: 999;
}

.section-one-glass {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}


.about-us::after,
.about-dedication::after {
  content: "";
  background-color: var(--pizza-red);
  opacity: 0.3;
  width: 100%;
  height: 1px;
  bottom: 0;
  z-index: 2;
}

/**** About dedication ***/

.about-dedication {
  background-image: url("../../assets/images/about-us/section-two.jpeg");
}

.about-dedication .content {
  position: relative;
  z-index: 1;
  width: 90%;
  text-align: left;
  margin: 0 auto;
}
.dark-glass {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.411);
  backdrop-filter: blur(1px);
}
.black-glass {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}

/**** About customers ***/
.about-customers .content {
  margin: 0 auto;
  /* width: 100%; */

}

.about-customers .images {
  /* padding-left: 30px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}
.about-customers .images img {
  min-width: 10rem;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  border-radius: 15px;
}

.img-glass {
  width: 100%;
  height: 25rem;
  bottom: 0;
  background-image: url("../../assets/images/about-us/g1.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  opacity: 0.7;
}
/**** for customer ***/

/**** About customers ***/
.about-customer .content {
  margin: 0 auto;
}

.about-customer .images {
  /* padding-left: 30px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}
.about-customer .images img {
  min-width: 10rem;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  border-radius: 250px;
}

/**** Responsive ***/

@media screen and (min-width: 700px) {
  .about-dedication .content,
  .about-customers .content,
  .about-careers .content,
  .about-customer .content,

  .about-collaboration .content {
    padding: 2rem;
  }
  

}

@media screen and (min-width: 1025px) {
  .about-dedication .content,
  .about-customer .content,

  .about-customers .content {
    width: 70%;
  }
  .about-customers .images {
    flex-direction: row;
  }
  .about-customer .images {
    flex-direction: row;
  }
  .about-careers,
  .about-collaboration {
    grid-template-columns: repeat(2, 1fr);
  }
  /* .about-us .images {
    grid-template-columns: repeat(4, 1fr);
    ;
  } */

}
@media screen and (min-width: 1110px) {
  .about-dedication .content,
  .about-customer .content {
    width: 50%;
  }
  .about-customers .content {
    width: 50%;
  }
}




/**** About us ***/
.about-us .images {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us .images img {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}

.about-us .next-button {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  z-index: 10;
}

@media screen and (min-width: 700px) {
  .about-us .images {
      grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 1025px) {
  .about-us .images {
      grid-template-columns: 1fr;
  }
}










































































/* ProfileCard.css */

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  padding: 0 20px; 
}

.contact-usss{
  font-size: 2.5em; /* Default font size */
  font-weight: bold;
  /* margin-bottom: 20px; */
  text-align: center;
  color: #fff; /* White color for text to stand out against dark background */

}
.contact-ussss{
  font-size: 1em; /* Default font size */
  font-weight: bold;
  /* margin-bottom: 20px; */
  /* text-align: center; */
  color: #fff; /* White color for text to stand out against dark background */

}

.team-heading {
  font-size: 2.5em; /* Default font size */
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #fff; /* White color for text to stand out against dark background */
  letter-spacing: 1.5px; /* Adds space between letters */
}

.profile-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Adds spacing between profile cards */
  margin-top: 20px;
}

.profile-card {
  text-align: center;
  padding: 10px;
  width: 100%;
  max-width: 200px; /* Limit maximum width for larger screens */
  box-sizing: border-box; /* Ensure padding is included in width */
  border: none; /* Remove the border */
  background-color: transparent; /* Remove the background color */
}

.profile-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
  border-radius: 10px; /* Optional: Add some rounding to the image corners */
}

.profile-details {
  margin-top: 10px;
}

.profile-name {
  font-size: 1.1em; /* Reduced the font size */
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff; /* White text to stand out against dark background */
}



.profile-role {
  font-size: 0.9em; /* Smaller font size for the role */
  color: lightgray; /* Light gray color for role */
  margin-bottom: 5px;
}

.profile-description {
  font-size: 0.8em; /* Reduced font size for the description */
  color: darkgray; /* Dark gray color for description */
}

/* Media Queries for Responsive Design */

/* For smaller devices (phones, etc.) */
@media (max-width: 600px) {
  .profile-container {
      padding: 0 10px;
  }

  .profile-card {
      max-width: 100%;
      margin-bottom: 20px; /* Add margin to the bottom of each card */
  }

  .profile-grid {
      gap: 10px; /* Reduce the gap between cards */
  }

  .profile-name {
      font-size: 1em; /* Further reduce text size for small screens */
  }

  .profile-role {
      font-size: 0.8em;
  }

  .profile-description {
      font-size: 0.7em;
  }
}

/* For larger devices (desktops, etc.) */
@media (min-width: 1200px) {
  .profile-container {
      padding: 0 40px;
  }

  .profile-card {
      max-width: 250px; /* Slightly larger cards for bigger screens */
  }

  .profile-name {
      font-size: 1.2em; /* Slightly larger text size for large screens */
  }

  .profile-role {
      font-size: 1em;
  }

  .profile-description {
      font-size: 0.9em;
  }

  }
  











