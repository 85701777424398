.modal.active-modal {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--modal-glass-bg);
  backdrop-filter: blur(5px);
  z-index: 999;
}
.modal {
  display: none;
}

.modal-main {
  position: static;
  border-radius: 15px;
  background-color: var(--white);
  backdrop-filter: blur(5px);
  box-shadow: var(--modal-box-shadow);
  -webkit-box-shadow: var(--modal-box-shadow);
  -moz-box-shadow: var(--modal-box-shadow);
  width: 90%;
  -webkit-align-self: center;
  align-self: center;
  margin: 0 auto;
  margin-top: 10%;
  min-width: max-content;
  max-width: 600px;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 auto;
  width: 90%;
  padding: 3rem 1rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.close-modal-btn {
  cursor: pointer;
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--light-black);
  font-size: 2rem;
  text-align: right;
  padding: 1rem;
  width: max-content;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 99999;
}
.modal-content h2 {
  color: var(--pizza-red);
  font-family: var(--poppins-font);
  letter-spacing: 1px;
  font-weight: 400;
}

.modal-content form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.modal-content input {
  width: 90%;
  background-color: transparent;
  border: 1px solid transparent;
  border: 1px solid var(--light-black);
  padding: 1rem;
}

.modal-content input:focus-visible {
  outline: none;
}
.modal-content input::placeholder {
  color: var(--light-black);
  text-transform: capitalize;
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
}

.modal-content input[type="email"],
.modal-content input[type="password"],
.modal-content input[type="text"] {
  color: var(--light-black);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
}

.modal-extras {
  position: absolute;
  top: 94%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.modal-forgot-password {
  color: var(--light-black);
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
}

.login-and-signup {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

.login-and-signup .modal-signup-btn,
.login-and-signup .modal-login-btn {
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid transparent;
  padding: 0.7rem 1rem;
  width: 100%;
  text-transform: uppercase;
  border-radius: 10px;
  color: var(--light-black);
  background-color: transparent;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
}

.login-and-signup .modal-signup-btn {
  background-color: var(--yellow);
  text-align: center;
}

.login-input-err {
  color: var(--red);
  text-align: left;
}
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding-bottom: 3rem;
}
.loader p {
  color: var(--light-black);
  font-family: var(--poppins-font);
  letter-spacing: 1px;
}
.loader img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}

@media (hover: hover) {
  .login-and-signup .modal-signup-btn:hover {
    opacity: 0.7;
    transition: all ease-in-out 0.3s;
  }
  .login-and-signup .modal-login-btn:hover {
    border: 1px solid var(--yellow);
    transition: all ease-in-out 0.3s;
  }
}
