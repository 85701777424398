/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 1rem;
  min-height: 100vh;
  padding-bottom: 10rem;
  background-color: var(--light-black);
  color: var(--white);
  font-family: var(--poppins-font);
  background-image: none;
}
.checkout h2 {
  margin: 0 auto;
  font-size: 3rem;
}

.checkout-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.checkout-item {
  display: grid;
  grid-template-columns: 10rem 1fr;
  gap: 2rem;
  width: max-content;
  height: 10rem;
}
.checkout-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.checkout-item-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.checkout-item-info h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--yellow);
}
/* PERSONAL INFORMATION */
.checkout-personal-information h3 {
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--yellow);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.checkout-address,
.checkout-number {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.checkout-personal-information h3 a,
.checkout-address a,
.checkout-number a {
  font-size: 1rem;
  color: rgb(103, 15, 186);
}

.checkout-personal-information,
.checkout-personal-information form,
.checkout-choose-delivery,
.checkout-choose-delivery form,
.checkout-choose-payment,
.delivery,
.promo-code {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.promo-code input {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  border: 1px solid var(--light-gray);
  padding: 1rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 1rem;
  width: 20%;
}

/* CHOOSE DELIVERY */
.checkout-choose-delivery form label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 50%;
}
.takeaway[type="radio" i],
.delivery[type="radio" i] {
  -moz-appearance: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--white);
  padding: 0.5rem;
  border-radius: 50%;
}
.takeaway[type="radio" i]:checked,
.delivery[type="radio" i]:checked {
  border: 1px solid var(--white);
  background-color: var(--pizza-red);
}
.takeaway:checked,
.delivery:checked {
  width: 1rem;
}

.takeaway-option,
.delivery-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.takeaway-option svg {
  width: 1rem;
  height: 1rem;
}
/* PROMOCODE */
.promo-code p {
  cursor: pointer;
  color: rgb(103, 15, 186);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  transition: all ease-in-out 0.2s;
  text-decoration: underline;
}
.promo-code p:hover {
  color: white;
  transition: all ease-in-out 0.2s;
}

input[type="password"] {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
}

/* CART TOTALS */

.checkout-carttotals .cart-carttotals .totals-content {
  display: flex;
  flex-direction: row;
  font-family: var(--poppins-font);
  color: var(--whitish-gray);
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.2rem;
  gap: 1rem;
}
.cart-totals {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.checkout-carttotals .totals-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
}
.checkout-carttotals .totals-content h4 {
  font-weight: 300;
}
.checkout-carttotals .totals-content p {
  font-weight: 600;
  color: var(--yellow);
}

.checkout-choose-payment input[type="date"] {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
}

/* BUTTONS */
.checkout-interaction-btns {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
}

.checkout-choose-payment label {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 700px) {
  .checkout-choose-payment label {
    flex-direction: row;
    justify-content: space-between;
  }
}
